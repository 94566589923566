.st0{
    fill: none;
    stroke: #000000; 
    stroke-width: 0.5; 
    stroke-miterlimit: 10;
}

.st1{
    fill:#414042;
}

.st2{
    fill:#FFFFFF;
    stroke:#414042;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
}

.st3{
    fill:#001F60;
}

.st4{
    fill:#E1251B;
}

.st5{
    fill:#FFFFFF;
}

.st6{
    fill:#001F60;
    stroke:#414042;
    stroke-width:0.5;
    stroke-miterlimit:10;
}

.rec-st {
    fill:none;
    stroke-width:0.8;
}